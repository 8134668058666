import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCall } from '../../utils/apiCall';
import styles from '../../Styles/AdminLogin.module.css';
import Form from "../../components/Auth/Form";


function Admin() {
    const navigate = useNavigate();
    const [error_message, setErrorMessage] = useState("");
    const handleSignIn = async ({ email, createpassword }) => {
        try {
            const response = await apiCall('adminlogin', 'POST',{ email, createpassword });
             console.log("REQ_Body", email, createpassword);
            if (response.success) {
                // const data = await response.json();
                const admintoken = response.admintoken;
                localStorage.setItem('admintoken', admintoken);
                navigate('/UploderPage');
                alert("Logged in Successfully");
            } else {
                alert("Login Failed");
            }
        }
        catch (error) {
            console.error('Error making API call:', error);
            setErrorMessage("Error making API call");
        }
    }
    return (
        <div>
        <div className={styles.headText}>---Welcome to the Admin Portal---</div>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <Form
                        onSignIn={handleSignIn}
                        isLoginForm={false}
                        showFullName={false}
                        showConfirmPassword={false}
                        isAdmin={true}
                    />
                    {error_message && <p className={styles.error}>{error_message}</p>}
                </div>
            </div>
            </div>
    );
}

export default Admin;
