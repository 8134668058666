import React from 'react';
import styles from '../../Styles/SubjectCard.module.css';
import { GiAbstract062 } from "react-icons/gi";
import { IoBookSharp } from "react-icons/io5";
import { ImUserTie } from "react-icons/im";

const SubjectCard = ({ subject, arr_index, handleInputChange }) => {
    return (
        <div className={styles.crad} key={arr_index}>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder={`Subject ${arr_index + 1}`}
                    name={`subject[${arr_index}].subjectName`}
                    value={subject.subjectName}
                    onChange={(e) => handleInputChange(e, arr_index)}
                />
                <GiAbstract062 className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Book Name"
                    name={`subject[${arr_index}].bookName`}
                    value={subject.bookName}
                    onChange={(e) => handleInputChange(e, arr_index)}
                />
                <IoBookSharp className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Author of the Book"
                    name={`subject[${arr_index}].author`}
                    value={subject.author}
                    onChange={(e) => handleInputChange(e, arr_index)}
                />
                <ImUserTie className={styles.icon} />
            </div>
        </div>
    );
};

export default SubjectCard;