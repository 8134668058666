import React from 'react'
import TeacherLayout from '../../Layouts/TeacherLayout'
import Form from "../../components/Auth/Form";
import styles from '../../Styles/TeacherLogIn.module.css';
import { apiCall } from '../../utils/apiCall';
import { useNavigate } from "react-router-dom";
export default function TeacherLogin() {
  
  const navigate = useNavigate();
  const handleSignIn = async ({ email, createpassword }) => {
    try {
      const response = await apiCall('teacherlogin', 'POST', { email, createpassword });
      console.log("Response", response);
      if (response.success) {
        console.log('Login successful');
        const teachertoken = response.token;
        localStorage.setItem('teachertoken', teachertoken);
        alert(response.message);
        navigate('/TeacherDashboard');        
      } else {
        alert(response.message);
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  }

  return (
    <TeacherLayout>
      <div className={styles.formContainer}>
        <Form isLoginForm={true} showFullName={false} showConfirmPassword={false} onSignIn={handleSignIn} />
      </div>
    </TeacherLayout>
  )
}
