import React from "react";
import { RiAccountPinCircleFill,RiMailFill } from "react-icons/ri";
import { FaMagento, FaPhone } from "react-icons/fa";
import { IoSchool } from "react-icons/io5";
import { VscSymbolClass } from "react-icons/vsc";
import { MdSpaceDashboard } from "react-icons/md";
import styles from '../../Styles/Registration.module.css';

function TextInputWithIconGroup({ formData, handleInputChangedt }) {
    return (
        <>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Full Name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <RiAccountPinCircleFill className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Age"
                    name="age"
                    value={formData.age}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <FaMagento className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <RiMailFill className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Phone No."
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <FaPhone className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Name of Your School"
                    name="schoolName"
                    value={formData.schoolName}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <IoSchool className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Class"
                    name="studentClass"
                    value={formData.studentClass}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <VscSymbolClass className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Enter your board"
                    name="board"
                    value={formData.board}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <MdSpaceDashboard className={styles.icon} />
            </div>
        </>
    );
}

export default TextInputWithIconGroup;
