import React, { useState } from 'react'
import AdminLayout from '../../Layouts/AdminLayout'
import { ClassDropdown, Board, Subject, Assesment } from "../../components/Dropdowns/index";
import styles from "../../Styles/CourseDetails.module.css"
import { apiCallAdmin } from '../../utils/apiCallAdmin';
import { AssementTable } from '../../components/Admin/index';

function CourseDetails() {
  const [selected_board, setSelectedboard] = useState("");
  const [selected_className, setSelectedclassName] = useState("");
  const [selected_fileType, setSelectedfiletype] = useState("");
  const [selected_subject, setSelectedsubject] = useState("");
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handelViewCourse = async () => {
    let endpoint = `search/${selected_fileType}/${selected_board}/${selected_className}/${selected_subject}`;
    try {
      const response = await apiCallAdmin(endpoint, 'GET');
      console.log('API Response:', response);
  
      if (response.success) {
        const responseData = response.data[selected_fileType === 'Test' ? 'test' : 'lecture'];
        console.log('Filtered Response Data:', responseData);
  
        if (Array.isArray(responseData)) {
          setData(responseData);
        } else {
          console.error('API response is not an array:', responseData);
          alert('The data received is not in the expected format.');
        }
      } else {
        alert(`Error: ${response.message}`);
      }
    } catch (error) {
      console.error('API call error:', error);
      alert('An error occurred while fetching the data. Please try again.');
    }
  };
  
  const handleViewClick = (row) => {
    const dataKey = selected_fileType === 'Test' ? 'testData' : 'lectureData';
    setModalData(row.original[dataKey]);
    setIsModalOpen(true);
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const columns = [
    { header: 'Chapter', accessorKey: 'chapter' },
    { header: 'Board', accessorKey: 'board' },
    { header: 'Class', accessorKey: 'className' },
    { header: 'Subject', accessorKey: 'subject' },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <button onClick={() => handleViewClick(row)}>View</button>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className={styles.container}>
        <div className={styles.drop_container}>
          <Board value={selected_board} onChange={(e) => setSelectedboard(e.target.value)} />
        </div>
        <div className={styles.drop_container}>
          <ClassDropdown value={selected_className} onChange={(e) => setSelectedclassName(e.target.value)} />
        </div>
        <div className={styles.drop_container}>
          <Subject value={selected_subject} onChange={(e) => setSelectedsubject(e.target.value)} />
        </div>
        <div className={styles.drop_container}>
          <Assesment value={selected_fileType} onChange={(e) => setSelectedfiletype(e.target.value)} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.view} onClick={handelViewCourse}>View</button>
      </div> 
      <AssementTable data={data} columns={columns} />
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={handleCloseModal}>X</button>
            <pre>{JSON.stringify(modalData, null, 2)}</pre>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}

export default CourseDetails;
