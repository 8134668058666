import React from "react";
import styles from '../../Styles/LogIn.module.css';
import GeneralLayout from "../../Layouts/GeneralLayout";
import Form from "../../components/Auth/Form";
import { useNavigate } from "react-router-dom";
import { apiCall } from '../../utils/apiCall';

function Login() {
    const navigate = useNavigate();
    const handleSignIn = async ({ email, createpassword }) => {
        try {
            const response = await apiCall('login', 'POST', { email, createpassword });
            console.log("Response", response);
            if (response.success) {
                console.log('Login successful');
                const usertoken = response.usertoken;
                const refreshToken = response.refreshToken;
                localStorage.setItem('usertoken', usertoken);
                sessionStorage.setItem('refreshToken',refreshToken);
                navigate('/Registration');
                alert("Logged in Successfully");

            } else {
                alert("LogIn Failed");
                console.error('Login failed');
            }
        } catch (error) {
            console.error('Error making API call:', error);
        }
    };


    const handleNavigation = (path) => {
        navigate(path);
    };
    return (
        <GeneralLayout>
            <div className={styles.container}>
                <div className={styles.right_pane}>
                    <p className={styles.text1}>Don't have an account !!</p>
                    <p className={styles.text2} onClick={() => handleNavigation("/signup")}>Click here to SignUp</p>
                </div>
                <div className={styles.left_pane}>
                    <Form
                        isLoginForm={true}
                        showFullName={false}
                        showConfirmPassword={false}
                        onSignIn={handleSignIn}
                    />
                </div>
            </div>
        </GeneralLayout>
    );
}

export default Login;