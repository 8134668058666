import React, { useState, useEffect } from 'react';
import AdminLayout from '../../Layouts/AdminLayout';
import { apiCallAdmin } from '../../utils/apiCallAdmin';
import { AssementTable } from '../../components/Admin/index';

export default function TeacherDetails() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchTeacherDetails();
  }, []);

  const columns = [
    {
      header: 'Sl.NO',
      accessorKey: 'id',
    },
    {
      header: 'Name',
      accessorKey: 'fullName',
    },
    {
      header: 'Age',
      accessorKey: 'age',
    },
    {
      header: 'Contact NO',
      accessorKey: 'phoneNo',
    },
    {
      header: 'School',
      accessorKey: 'schoolName',
    },
    {
      header: 'College',
      accessorKey: 'college',
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Marks-10',
      accessorKey: 'classt',
    },
    {
      header: 'Marks-12',
      accessorKey: 'classtw',
    },
    {
      header: 'Password',
      accessorKey: 'password',
    },
    {
      header: 'Approve',
      accessorKey: 'approve',
      cell: ({ row }) => (
        <div onClick={() => handleApprove(row.original.id)} style={{ color: 'green', cursor: 'pointer' }}>
          Approve
        </div>
      ),
    },
    {
      header: 'Generate',
      accessorKey: 'generate',
      cell: ({ row }) => (
        <div onClick={() => handleGenerate(row.original.id)} style={{ color: 'blue', cursor: 'pointer' }}>
          Generate
        </div>
      ),
    },
  ];

  const fetchTeacherDetails = async () => {
    try {
      const response = await apiCallAdmin('fetchallTeacher', 'GET');
      if (response.success) {
        setData(response.data);
      } else {
        console.error('Failed to fetch teacher details:', response.message);
      }
    } catch (error) {
      console.error('API call error:', error);
    }
  };

  const handleApprove = async (id) => {
    try {
      const teacher = data.find((teacher) => teacher.id === id);
      
      if (!teacher) {
        console.error('Teacher not found');
        return;
      }
  
      const payload = {
        email: teacher.email,
        password: teacher.password, 
        is_active: false,
      };
      const response = await apiCallAdmin('teacherCredential', 'POST', payload);
      if (response.success) {
        alert(response.message);
        fetchTeacherDetails(); 
      } else {
        alert('Failed to approve teacher: ' + response.message);
      }
    } catch (error) {
      console.error('API call error:', error);
    }
  };
  
  
  const handleGenerate = (id) => {
    const generatePassword = () => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let password = '';
      for (let i = 0; i < 10; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return password;
    };
  
    const newPassword = generatePassword();
    setData((prevData) =>
      prevData.map((teacher) =>
        teacher.id === id ? { ...teacher, password: newPassword } : teacher
      )
    );
  };
  
  

  return (
    <AdminLayout>
      <AssementTable data={data} columns={columns} />
    </AdminLayout>
  );
}
