import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        console.log("Time>>>>>",decodedToken.exp < currentTime)
        return decodedToken.exp < currentTime;
    } catch (error) {
        return true;
    }
};

const PrivateRoute = ({ element, ...props }) => {
    const token = localStorage.getItem('usertoken');

    if (!token) {
        alert("Please log in to continue.");
        return <Navigate to="/Login" />;
    }

    if (isTokenExpired(token)) {
        alert("Your session has expired. Please sign in again.");
        return <Navigate to="/Login" />;
    }

    return React.cloneElement(element, props);
};

export default PrivateRoute;
