import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/common/Navbar";
import { apiCall } from "../utils/apiCall";

export default function GeneralLayout({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const refreshUserToken = async () => {
      try {
        const expiredUserToken = localStorage.getItem("usertoken");
        const refreshToken = sessionStorage.getItem("refreshToken");

        console.log("Attempting to refresh token...");
        console.log("Expired User Token:", expiredUserToken);
        console.log("Refresh Token:", refreshToken);

        if (expiredUserToken && refreshToken) {
          const response = await apiCall(
            "refreshtoken",
            "POST",
            { refreshToken: refreshToken },
            { authorization: expiredUserToken } // Pass the expired token in headers
          );

          console.log("API Response:", response);

          if (response.userToken) {
            localStorage.setItem("usertoken", response.userToken);
            console.log("User token updated.");
          } else if (response.message === "Please login again") {
            alert(response.message || "Please login again");
            navigate('/Login');
          } else {
            console.log("Token is still valid or an error occurred:", response);
          }
        } else {
          console.log("Missing tokens; API call skipped.");
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    };

    const intervalId = setInterval(refreshUserToken, 30000);

    return () => clearInterval(intervalId);
  }, []); // Dependency array empty to ensure this effect runs once on mount

  return (
    <div>
      <Navbar />
      {children}
    </div>
  );
}
