import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        console.log("Decoded Token:", decodedToken);
        const currentTime = Date.now() / 1000;
        console.log("Current Time:", currentTime);
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error("Token Decoding Error:", error);
        return true;
    }
};

const PrivateRouteTeacher = ({ element, ...props }) => {
    const teachertoken = localStorage.getItem('teachertoken');
console.log("Retrieved Token:", teachertoken);

    if (!teachertoken) {
        alert("Please log in to continue.");
        return <Navigate to="/TeacherLogin" />;
    }

    if (isTokenExpired(teachertoken)) {
        alert("Your session has expired. Please sign in again.");
        return <Navigate to="/TeacherLogin" />;
    }

    return React.cloneElement(element, props);
};

export default PrivateRouteTeacher;
