import React from 'react'
import styles from '../../Styles/TopPannel.module.css'
import { useNavigate } from "react-router-dom";


function TopPannel() {

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <p className={styles.tag}onClick={() => handleNavigation("/UploderPage")}>Upload Files</p>
                    <p className={styles.tag}onClick={() => handleNavigation("/LandingPage")}>View Student Details</p>
                    <p className={styles.tag}onClick={() => handleNavigation("/CourseDetails")}>View Course Details</p>
                    <p className={styles.tag}onClick={() => handleNavigation("/TeacherDetails")}>View Teacher Details</p>
                </div>
            </div>
        </div>

    )
}
export default TopPannel;
