import React, { useState } from "react";
import styles from '../../Styles/Navbar.module.css';
import imageSrc from "../../Pages/images/18.png";
import { useNavigate } from "react-router-dom";

function Navbar() {
    const [menuActive, setMenuActive] = useState(false);
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        setMenuActive(false); // Close menu after navigation
    };

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    return (
        <div className={styles.main}>
            <img src={imageSrc} alt="Website Logo" className={styles.logo} />
            
            {/* Hamburger Menu */}
            <div className={styles.hamburger} onClick={toggleMenu}>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
            </div>

            {/* Navigation Menu */}
            <div className={`${styles.navmenu} ${menuActive ? styles.active : ""}`}>
                <p onClick={() => handleNavigation("/")} className={styles.link}>Home</p>
                <p onClick={() => handleNavigation("/about")} className={styles.link}>About Us</p>
                <p onClick={() => handleNavigation("/signup")} className={styles.link}>SignUp</p>
                <p onClick={() => handleNavigation("/registration")} className={styles.link}>Registration</p>
                <p onClick={() => handleNavigation("/learn")} className={styles.link}>Learn Here</p>
            </div>
        </div>
    );
}

export default Navbar;
