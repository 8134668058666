import React from "react";
import styles from '../Styles/Home.module.css';
import GeneralLayout from "../Layouts/GeneralLayout";
import Navbar from "../components/common/Navbar";

function Home() {
    return (
        <>
            <Navbar />
            <div className={styles.text}>
                Alif-Lam-Mim Mission's (ALM) sacred mission is underpinned by unwavering devotion and a profound sense of duty to uplift the hearts and minds of the young scholars,
                nurturing them as torchbearers of knowledge and faith. Alif-Lam-Mim is not a conventional school or a Madrasa; rather, it's a residential home for underprivileged young students.
                ALM aspires to foster an environment where their dreams can take flight, where they can transcend the limitations of their circumstances, and where they can emerge as exemplars of faith,
                intellect, and virtue. In this endeavor, we humbly seek the support and blessings of the Almighty, recognizing that our journey towards enlightenment is inseparable from His divine will
                and guidance. With steadfast determination, we pledge to illuminate the path of these young souls, instilling in them the eternal values of our faith as they embark on their academic journeys.
                Together, guided by the divine light, we shall strive to empower a generation of young scholars, fostering a brighter future for our nation and the global community.
            </div>
        </>
    );
}

export default Home;
