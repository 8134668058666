import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCall } from '../../utils/apiCall';
import styles from '../../Styles/SignUp.module.css';
import Form from "../../components/Auth/Form";
import GeneralLayout from "../../Layouts/GeneralLayout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignUp() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        fullName: '',
        createpassword: '',
        confirmpassword: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSignIn = async (formData) => {
        try {
            const response = await apiCall('saveSignupDetails', 'POST', formData);
            console.log("first", formData);

            if (response.success) {
                toast.success("Sign-in successful!");
                console.log("SUCCESS MESSAGE",response.message)
                navigate('/Login');
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <GeneralLayout>
            <div className={styles.container}>
                <div className={styles.left_pane}>
                    <Form
                        onSignIn={handleSignIn}
                        formData={formData}
                        onInputChange={handleInputChange}
                    />
                </div>
                <div className={styles.right_pane}>
                    <p className={styles.text1}>Have an account!!</p>
                    <p className={styles.text2} onClick={() => handleNavigation("/login")}>Click here to LogIn</p>
                </div>
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </GeneralLayout>
    );
}

export default SignUp;
