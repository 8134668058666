import React from 'react';
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import SignUp from './Pages/Auth/SignUp';
import Registration from './Pages/Users/Registration';
import Learn from './Pages/Users/Learn';
import Adminlogin from './Pages/Admin/Adminlogin';
import Login from './Pages/Auth/Login';
import LandingPage from './Pages/Admin/LandingPage';
import UploderPage from './Pages/Admin/UploderPage';
import PrivateRoute from './Pages/Routes/PrivateRoute';
import CourseDetails from './Pages/Admin/CourseDetails';
import StudentDetailPage from './Pages/Admin/StudentDetailPage';
import PrivateRoutsAdmin from './Pages/Routes/PrivateRoutsAdmin';
import PrivateRouteTeacher from './Pages/Routes/PrivateRouteTeacher';
import TeacherLogin  from './Pages/Teacher/TeacherLogin';
import TeacherReg from './Pages/Teacher/TeacherReg';
import TeacherDashboard from './Pages/Teacher/TeacherDashboard';
import TeacherDetails from './Pages/Admin/TeacherDetails';
import ResetPassword from './Pages/Teacher/ResetPassword';

function App() {
  return (
    
    <BrowserRouter>
      <Routes>
        <Route  path='/' element={<Home />} />
        <Route  path='/About' element={<About />} />
        <Route  path='/SignUp' element={<SignUp />} />
        <Route  path='/Login' element={<Login />} />
        <Route  path='/Registration' element={<PrivateRoute element={<Registration />}/>} />
        <Route  path="/Learn" element={<PrivateRoute element={<Learn />} />}/>       
        <Route path="/Adminlogin" element={<Adminlogin />} />
        <Route path="/TeacherLogin" element={<TeacherLogin />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/TeacherDashboard" element={<PrivateRouteTeacher element={<TeacherDashboard />} />} />
        <Route path="/TeacherReg" element={<TeacherReg />} />
        <Route path="/LandingPage" element={<PrivateRoutsAdmin element={<LandingPage />}/>} />
        <Route path="/StudentDetailPage/:student_id" element={<PrivateRoutsAdmin element={<StudentDetailPage />} />} />
        <Route  path='/UploderPage' element={<PrivateRoutsAdmin element={<UploderPage />} />}/>
        <Route path='/CourseDetails' element={ <PrivateRoutsAdmin element={<CourseDetails />} />} />
        <Route path='/TeacherDetails' element={ <PrivateRoutsAdmin element={<TeacherDetails />} />} />
      </Routes>
  </BrowserRouter>
  
  );
}

export default App;
