import React from 'react';
import styles from '../../Styles/TestDetails.module.css';

function TestDetails({
    show_test_details,
    testData,
    calculateFullMarks,
    calculateMarksObtained,
    showanswer,
    selectedOptions,
    filledBlanks,
    handleCheckboxChange,
    handleFillInTheBlanksChange,
}) {
    return (
        <div className={styles.test_details}>
            <div className={styles.marks}>
                <div className={styles.fullMarks}>Full Marks: {calculateFullMarks()}</div>
                {showanswer && (
                    <div className={styles.marksOtained}>Marks Obtained: {calculateMarksObtained()}</div>
                )}
            </div>
            {show_test_details && testData && testData.question.map((question, questionIndex) => (
                <div key={questionIndex} className={styles.question_container}>
                    {question.exam_type === 'mcq' && (
                        <>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>{question.question}</p>
                                <p className={styles.elements}>Marks: {question.score}</p>
                                {showanswer && (
                                    <p className={styles.correct_elements}>Correct Answer: {question.answer}</p>
                                )}
                            </div>
                            <div className={styles.option_element_one}>
                                {question.options.map((option, optionIndex) => (
                                    <div key={optionIndex} className={styles.option}>
                                        <input
                                            type="checkbox"
                                            checked={selectedOptions[questionIndex] === optionIndex}
                                            onChange={() => handleCheckboxChange(questionIndex, optionIndex)}
                                        />
                                        {option}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {question.exam_type === 'fill in the blanks' && (
                        <div>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>{question.question}</p>
                                <p className={styles.elements}>Marks: {question.score}</p>
                                {showanswer && (
                                    <p className={styles.correct_elements}>Correct Answer: {question.answer}</p>
                                )}
                            </div>
                            <div className={styles.option_element_one}>
                                <input
                                    type="text"
                                    value={filledBlanks[questionIndex] || ''}
                                    onChange={(e) => handleFillInTheBlanksChange(questionIndex, e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    {question.exam_type === 'short answer' && (
                        <div>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>{question.question}</p>
                                <p className={styles.elements}>Marks: {question.score}</p>
                                {showanswer && (
                                    <p className={styles.correct_elements}>Correct Answer: {question.answer}</p>
                                )}
                            </div>
                            <div className={styles.option_element_one}>
                                <textarea
                                    rows="2"
                                    value={filledBlanks[questionIndex] || ''}
                                    onChange={(e) => handleFillInTheBlanksChange(questionIndex, e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    {question.exam_type === 'long answer' && (
                        <div>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>{question.question}</p>
                                <p className={styles.elements}>Marks: {question.score}</p>
                                {showanswer && (
                                    <p className={styles.correct_elements}>Correct Answer: {question.answer}</p>
                                )}
                            </div>
                            <div className={styles.option_element_one}>
                                <textarea
                                    rows="5"
                                    value={filledBlanks[questionIndex] || ''}
                                    onChange={(e) => handleFillInTheBlanksChange(questionIndex, e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                </div>
            ))}
        </div>
    );
}

export default TestDetails;
