import React from "react";
import styles from '../../Styles/FileUploaderForm.module.css';
import { ClassDropdown, Board, Subject, Test } from "../../components/Dropdowns/index";
import { VscBrowser } from "react-icons/vsc";
import { MdOutlineFileUpload } from "react-icons/md";

function FileUploaderForm({
    isLectureChecked,
    isTestChecked,
    handleLectureCheckboxChange,
    handleTestCheckboxChange,
    selectedboard,
    setSelectedboard,
    selectedclassName,
    setSelectedclassName,
    selectedsubject,
    setSelectedsubject,
    selectedtestType,
    setSelectedTestType,
    handleBrowseFiles,
    handleUpload,
    handleFileSelect
}) {
    return (
        <div className={styles.database_modal}>
            <div className={styles.checkboxContainer}>
                <div className={styles.checkboxWrapper}>
                    <div className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={isLectureChecked}
                            onChange={handleLectureCheckboxChange}
                        />
                        Lecture
                    </div>
                    <div className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={isTestChecked}
                            onChange={handleTestCheckboxChange}
                        />
                        Test
                    </div>
                </div>
            </div>
            <div className={styles.drop_container}>
                <Board value={selectedboard} onChange={(e) => setSelectedboard(e.target.value)} />
            </div>
            <div className={styles.drop_container}>
                <ClassDropdown value={selectedclassName} onChange={(e) => setSelectedclassName(e.target.value)} />
            </div>
            <div className={styles.drop_container}>
                <Subject value={selectedsubject} onChange={(e) => setSelectedsubject(e.target.value)} />
            </div>
            {isTestChecked && (
                <div className={styles.drop_container}>
                    <Test value={selectedtestType} onChange={(e) => setSelectedTestType(e.target.value)} />
                </div>
            )}
            <div className={styles.file_button}>
                <button className={styles.dashboard_button_files} onClick={handleBrowseFiles}>
                    Browse <VscBrowser className={styles.icon} />
                </button>
                <button className={styles.dashboard_button_files} onClick={handleUpload} >
                    Upload <MdOutlineFileUpload  className={styles.icon} />
                </button>
            </div>
            <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileSelect}
            />
        </div>
    );
}

export default FileUploaderForm;
