import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCall } from '../../utils/apiCall';
import { ClassDropdown, Board } from "../../components/Dropdowns/index";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
import GeneralLayout from "../../Layouts/GeneralLayout";
import styles from '../../Styles/Registration.module.css';
import SubjectCard from "../../components/Registration/SubjectCard";
import PersonalInfoCrad from "../../components/Registration/PersonalInfoCard"

function Registration() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [form_Data, setFormData] = useState({
        fullName: "",
        age: "",
        phoneNo: "",
        email : "",
        schoolName: "",
        studentClass: "",
        board: "",
        subjects: [
            { subjectName: "", bookName: "", author: "" },
            { subjectName: "", bookName: "", author: "" },
            { subjectName: "", bookName: "", author: "" },
            { subjectName: "", bookName: "", author: "" },
            { subjectName: "", bookName: "", author: "" },
        ],
    });


    const handleInputChangedt = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const subjects = [...form_Data.subjects];
        subjects[index][name.split(".")[1]] = value;
        setFormData((prevData) => ({ ...prevData, subjects }));
    };

    const handleNext = () => {
        if (step === 1 || form_Data.subjects.length < 5) {
            setStep((prevStep) => prevStep + 1);
        } else {
            alert("Only five subjects are allowed.");
        }
    };



    const handlePrev = () => {
        setStep((prevStep) => Math.max(prevStep - 1, 1));
    };

    const indexfunction = () => {
        let start_index = (step - 2) * 2;
        let end_index = Math.min(start_index + 2, form_Data.subjects.length);
        return (
            <div className={styles.subject_card_container}>
                {form_Data.subjects.map((subject, index) => (
                    <SubjectCard
                        key={index}
                        subject={subject}
                        arr_index={index}
                        handleInputChange={handleInputChange}
                    />
                ))}
            </div>
        );
    };



    const handleRegister = async () => {
        if (!form_Data.fullName || !form_Data.age || !form_Data.phoneNo || !form_Data.schoolName || !form_Data.email || form_Data.subjects.some(subject => !subject.subjectName || !subject.bookName || !subject.author)) {
            alert("All fields are mandatory");
            return;
        }
        try {
            const endpoint = 'saveStudentDetails';
            const result = await apiCall(endpoint, 'POST',form_Data);
            if (result.success) {
                alert("Successfully Registered")
                navigate('/Learn');
            } else {
                alert("All Fields are mandatory")
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    // const DropDownContainer = () => {
    //     return (
    //         <>
    //             <div className={styles.drop_container}>
    //                 <ClassDropdown value={form_Data.studentClass} onChange={(e) => handleInputChangedt(e)} />
    //             </div>
    //             <div className={styles.drop_container}>
    //                 <Board value={form_Data.board} onChange={(e) => handleInputChangedt(e)} />
    //             </div>
    //         </>
    //     )
    // }

    const ButtonIconConatiner = () => {
        return (
            <div className={styles.prog_icon_container}>
                <FaAngleDoubleLeft title="Previous" onClick={handlePrev} className={styles.prog_icon} />
                <button className={styles.procedebutton} onClick={handleRegister}>Register</button>
                <FaAngleDoubleRight title="Next" onClick={handleNext} className={styles.prog_icon} style={{ opacity: step > 1 && form_Data.subjects.length === 5 ? 0.5 : 1 }} />
            </div>
        )
    }
    return (
        <GeneralLayout>
            <div className={styles.dataform}>
                <div className={styles.crad}>
                    {step === 1 && (
                        <>
                            <PersonalInfoCrad formData={form_Data} handleInputChangedt={handleInputChangedt} />
                            {/* <DropDownContainer /> */}
                        </>
                    )}
                </div>
                {step >= 2 && (
                    <>
                        {indexfunction()}
                    </>
                )}
                <ButtonIconConatiner />
            </div>
        </GeneralLayout>
    );
}

export default Registration;
