import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        // If decoding fails, consider the token invalid
        return true;
    }
};

const PrivateRoutsAdmin = ({ element, ...props }) => {
    const admintoken = localStorage.getItem('admintoken');

    if (!admintoken) {
        alert("Please log in to continue.");
        return <Navigate to="/Adminlogin" />;
    }

    if (isTokenExpired(admintoken)) {
        alert("Your session has expired. Please sign in again.");
        return <Navigate to="/Adminlogin" />;
    }

    return React.cloneElement(element, props);
};

export default PrivateRoutsAdmin;
