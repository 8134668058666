import React from 'react';
import styles from '../../Styles/TaecherTopPannel.module.css';
import imageSrc from "../../Pages/images/18.png";
import { useNavigate } from 'react-router-dom';

function TaecherTopPannel() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className={styles.main}>
            <img src={imageSrc} alt="Website Logo" className={styles.logo} />
            <p className={styles.tag} onClick={() => handleNavigation("/TeacherReg")}>Apply Here!</p>
            <p className={styles.tag} onClick={() => handleNavigation("/TeacherLogin")}>Login</p>
        </div>
    );
}

export default TaecherTopPannel;
