import React, { useState, useEffect } from 'react';
import styles from '../../Styles/Modal.module.css';
import { RxCross2 } from "react-icons/rx";

const Modal = ({ isOpen, onClose, data, handleSave }) => {
    const [marks, setMarks] = useState(data.map(() => ""));
    const [totalMarks, setTotalMarks] = useState(0);

    useEffect(() => {
        calculateTotalMarks();
    }, [marks]);

    const handleMarksChange = (index, value) => {
        const newMarks = [...marks];
        newMarks[index] = value;
        setMarks(newMarks);
    };

    const calculateTotalMarks = () => {
        const total = marks.reduce((sum, mark) => sum + (parseFloat(mark) || 0), 0);
        setTotalMarks(total);
    };

    const onSave = () => {
        handleSave(totalMarks);
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <RxCross2 className={styles.closeButton} onClick={onClose}/>
                <h2>Details</h2>
                <table className={styles.modalTable}>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                            <th>Marks Obtained</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.question}</td>
                                <td>{item.answer}</td>
                                <td>
                                    <input
                                        type="number"
                                        value={marks[index]}
                                        onChange={(e) => handleMarksChange(index, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className={styles.totalMarks}>
                    <strong>Total Marks: {totalMarks}</strong>
                </div>
                <button className={styles.button} onClick={onSave}>Save</button>
            </div>
        </div>
    );
};

export default Modal;
